<template>
    <div :style="cardHeight" class="text-center d-flex flex-row w-100">
        <div class="flex-fill card mr-3 mb-0 d-flex">
            <div class="pull-right position-absolute" style="z-index: 99; right: 1rem;">
                <button type="button" class="btn btn-primary mt-2"
                        @clock="refreshData">
                    <i class="fas fa-sync-alt"></i> Refresh
                </button>
            </div>
            <highcharts class="mt-5 mr-1 h-100 d-flex flex-column flex-fill" :options="bootingChartOptions"></highcharts>
        </div>
        <div class="text-left flex-grow-0">
            
            <!--<div>
                <table class="table table-responsive table-bordered table-striped">
                    <tbody>
                        <tr>
                            <td>Total boot count</td>
                            <td>0</td>
                        </tr>
                        <tr>
                            <td>Boot success count</td>
                            <td>0</td>
                        </tr>
                        <tr>
                            <td>Boot failed count</td>
                            <td>0</td>
                        </tr>
                        <tr>
                            <td>Avg duration boot</td>
                            <td>0</td>
                        </tr>
                    </tbody>
                </table>
            </div>-->
        </div>
    </div>
</template>

<script>    
    import commonAct from '@/commonActionHandle.js';
    import vmMachineApi from '@/api/gpuHub/vmMachines';
    import chartDataApi from '@/api/common/chartData';
    import { Chart } from 'highcharts-vue';
    import Highcharts from 'highcharts'
    import { bootSpline } from "@/scripts/gpuHub/vmMachines/bootingChartOptions";
    import moment from 'moment'
    var momentDurationFormatSetup = require("moment-duration-format");
    momentDurationFormatSetup(moment);
    import {  vmMachineStatus } from "@/constant/vmMachineStatusConst";
    const colorSuccess = '#0BEA74';
    const colorDanger = '#F00';
    const colorNormal = '#000';
    export default {
        components: {
            highcharts: Chart
        },
        props: {
            cardHeight: {
                type: String,
                default: 'height: calc(100dvh - 12rem);'
            },
            machineItem: {
                type: Object,
                default: null
            },
        },
        watch: {
            machineItem(newVal) {
                if (this.machineItem !== null) {
                     this.getMachineBootHistoryData();
                }
            }
        },
        data() {
            return {
                intervalUpdateChart: null,
                bootingChartOptions: Object.assign(JSON.parse(JSON.stringify(bootSpline)), {
                    xAxis: {
		                categories: [],
		                title: {
			                text: '',
		                },
		                allowDecimals: false,
		                tickInterval: 1,
		                labels: {
			                enabled: true,
		                }
                    },
                    plotOptions: {
                        areaspline: {
			                dataLabels: {
				                enabled: true
			                },
			                fillColor: {
				                linearGradient: {
					                x1: 0,
					                y1: 0,
					                x2: 0,
					                y2: 1
				                },
				                stops: [
					                [0, Highcharts.getOptions().colors[3]],
					                [1, Highcharts.Color(Highcharts.getOptions().colors[8]).setOpacity(0.2).get('rgba')]
				                ]
			                },
			                marker: {
				                fillColor: Highcharts.getOptions().colors[1],
				                lineColor: Highcharts.getOptions().colors[1],
				                enabled: true
			                },
			                lineColor: '#303030'
		                },
		                series: {
			                tooltip: {
				                headerFormat: '<b>{series.name}</b><br>',
				                pointFormat: 'The average boot duration takes {point.y} minutes',
				                clusterFormat: 'Clustered points: {point.clusterPointsAmount}'
			                },
		                },
		                line: {
			                dataLabels: {
				                enabled: true
			                },
			                enableMouseTracking: false,
			                marker: {
				                fillColor: Highcharts.getOptions().colors[2],
				                lineColor: Highcharts.getOptions().colors[2],
				                enabled: false
			                }
		                }
	                },
                    series: [{
		                name: 'Average boot duration',
		                data: []
	                }]
                }),
                vmMachineStatus: vmMachineStatus,
                statisticMachineBootHistory: {},
            }
        },
        mounted() {
            this.getBootingAvgStatisticsByDate();
            this.intervalUpdateChart = setInterval(() => {
                this.getBootingAvgStatisticsByDate();
            }, 60000);
        },
        beforeDestroy() {
            clearInterval(this.intervalUpdateChart);
        },
        methods: {
            refreshData() {
                this.getBootingAvgStatisticsByDate();
            },
            getBootingAvgStatisticsByDate() {
                chartDataApi.getBootingAvgStatisticsByDate().then(response => {
                    if (response.data && response.data.result === 0) {
                        let dataChart = response.data.data.map(x => {
                            return {
                                y: Math.round((x.avgBootDuration + Number.EPSILON) * 100) / 100,
                                marker: {
                                    fillColor:  colorSuccess
                                }
                            };
                        });
                        this.bootingChartOptions.xAxis.categories = response.data.data.map(x => moment.utc(x.dateBoot).format('DD-MM-YYYY'));
                        this.bootingChartOptions.series[0].data = dataChart;
                    }
                    else {
                        if (response.data && response.data.message !== null && response.data.message !== '') {
                            commonAct.showError(response.data.message || this.$lang.common.error);
                        }
                    }
                }).catch(error => {
                    console.error(error);
                    commonAct.showError(error);

                });
            },
        }
    }
</script>